import React, { useState, useEffect } from "react"
import ImageMeta from "../components/ImageMeta"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import Seamless from "../components/SeamlessEmbed"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import LocationBlocks from "./contact/LocationBlocks"

import GeneralHours from "@bit/azheng.joshua-tree.general-hours"
import { Ratings } from "../templates/index/RatingsAndReviews"
import { graphql } from "gatsby"
import AllSocialReviews from "../components/SocialReviews/AllSocialReviews"

import locationMarker from "./contact/location-marker.svg"
import locationMarkerWhite from "./contact/location-marker-white.svg"

var classNames = require("classnames")

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
var converter = new showdown.Converter()

import {
  getPostObj,
  splitCamelCaseToString,
  parseSearchQuery,
} from "../utils/utils"
import LocationForms from "./contact/LocationForms"

const ContactUsPage = ({ pageContext, location, data }) => {
  var post
  var language
  let postObj = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  post = postObj.post
  language = postObj.language

  var locationHours = data.allLocationsJson.nodes[0].locationData.locationHours

  var locations = data.allLocationsJson.nodes

  // let locationOne = getLocationsData(data.allLocationsJson.nodes).main;
  // let locationTwo;
  // if (data.allLocationsJson.nodes[1]) locationTwo = getLocationsData(data.allLocationsJson.nodes).other;

  // let locationOneAll = getLocations(data.allLocationsJson.nodes).main;
  // let locationTwoAll;
  // if (data.allLocationsJson.nodes[1]) locationTwoAll = getLocations(data.allLocationsJson.nodes).other;

  // function getLocationsData(locations) {
  //   let mainLocation = data.dataJson.mainLocation
  //   let locationsObj = {}
  //   for (let i = 0; i < locations.length; i++) {
  //     let location = locations[i].pageData
  //     if (location.title === mainLocation + "/") {
  //       locationsObj.main = locations[i].locationData;
  //     } else {
  //       locationsObj.other = locations[i].locationData;
  //     }
  //   }
  //   return locationsObj
  // }

  function getLocations(locations) {
    let mainLocation = data.dataJson.mainLocation
    let locationsAllObj = {}
    for (let i = 0; i < locations.length; i++) {
      let location = locations[i].pageData
      if (location.title === mainLocation + "/") {
        locationsAllObj.main = locations[i]
      } else {
        locationsAllObj.other = locations[i]
      }
    }
    return locationsAllObj
  }

  const [activeForm, setActiveForm] = useState({ prevForm: "", newForm: "" })

  useEffect(() => {
    if (location.search) {
      const params = parseSearchQuery(location.search)

      if (params.f) {
        setActiveForm({
          ...activeForm,
          newForm: splitCamelCaseToString(params.f),
        })
      }
    } else {
      setActiveForm({ prevForm: "", newForm: "" })
    }
  }, [])

  const handleFormChange = (newForm) => {
    if (newForm === activeForm.newForm) {
      const form = document.getElementById("contact-page-form")
      setTimeout(() => {
        window.scroll({ top: form.offsetTop - 88, left: 0, behavior: "smooth" })
      }, 600)

      return
    } else {
      setActiveForm((prevState) => ({
        prevForm: prevState.newForm,
        newForm: newForm,
      }))
    }
  }

  return (
    <SharedStateProvider>
      <Layout
        className={`contact-us ${language === "es" ? "es" : null}`}
        layoutClass={"contact-fab"}
        language={language}
      >
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />
        <span className="menu-spacer"></span>

        <div className="contact-top-wrapper">
          <NuvoImage
            useAR
            className="splash"
            cloudName="nuvolum"
            publicId={post.googleMapsBackground.splashPublicId}
            width="auto"
          ></NuvoImage>

          {post.pinTextLineOne && (
            <a href={post.googleMapsBackground.pinLink} target="_blank">
              <div className="marker-heading">
                <img
                  className="marker"
                  src={locationMarker}
                  alt="Location Marker"
                />
                <h5>
                  {post.googleMapsBackground.pinTextLineOne}
                  {/* <br />
              {post.googleMapsBackground.pinTextLineTwo} */}
                </h5>
              </div>
            </a>
          )}
          <div className="contact-top-headings">
            <h1>{post.heading}</h1>
          </div>
        </div>

        <div className="body-section contact-us-body">
          <div className="columns">
            <div className="column is-4"></div>
            <div
              className="column is-10"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div className="contact-info is-hidden-tablet">
                {locations.length > 1 && (
                  <>
                    {/* <LocationBlocks language={language} locations={[locationTwoAll, locationOneAll]} /> */}
                  </>
                )}
                {locations.length === 1 && (
                  <>
                    <div className="contact-sidebar-block">
                      <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                      <a
                        href={post.googleMapsBackground.pinLink}
                        target="_blank"
                      >
                        {post.sidebar.address.street}
                        <br />
                        {post.sidebar.address.cityStateZip}
                      </a>
                    </div>
                    <div className="contact-sidebar-block">
                      <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                      <a href={post.sidebar.phone.phonePlusFormat}>
                        {post.sidebar.phone.phoneText}
                      </a>
                    </div>

                    <div className="contact-sidebar-block">
                      <GeneralHours
                        language={language}
                        contact={true}
                        locationHours={locationHours}
                      />
                    </div>
                    <div className="contact-reviews">
                      {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                      {/* <BigRating lanugage={language} google={google} facebook={facebook} post={post} coastal={coastal} east={east} /> */}
                    </div>
                  </>
                )}
              </div>
              <div
                dangerouslySetInnerHTML={createHtml(
                  converter.makeHtml(
                    post.contactBody.heading + post.contactBody.blurb
                  )
                )}
              ></div>
              {language !== "es" ? (
                <div className="contact-form-section">
                  {/* Use form for single Location */}
                  <div className="inline-block">
                    <h5>Send Us a Message</h5>{" "}
                    <span className="required-fields">
                      <span className="required-asterisk">*</span> Required
                      Fields
                    </span>
                  </div>
                  <Seamless id="11a37b53f12b72164b1146ba2bd50eb5" />

                  {/* Use for multiple locations with forms */}
                  {/* <div className="locations-grid">
                  <LocationBlocks 
                    language={language} 
                    locations={locations} 
                    setActiveForm={setActiveForm}
                    handleFormChange={handleFormChange}
                  />
                  </div> */}
                  {/* <div className="seamless-form-wrapper-2" dangerouslySetInnerHTML={{ __html: form}}>
  </div> */}
                </div>
              ) : (
                <div className="contact-reviews contact-reviews-es small">
                  {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                  {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
                  <AllSocialReviews isColumn />
                </div>
              )}
            </div>
            <div className="column is-2"></div>
            <div className="column is-narrow contact-info is-hidden-mobile">
              {locations.length > 1 && (
                <>
                  {/* <LocationBlocks language={language} locations={locations} /> */}
                  {language !== "es" && (
                    <div className="contact-sidebar-block">
                      {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
                      <AllSocialReviews isColumn />
                    </div>
                  )}
                </>
              )}
              {locations.length === 1 && (
                <>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Dirección" : "Address"}</h7>
                    <a href={post.googleMapsBackground.pinLink} target="_blank">
                      {post.sidebar.address.street}
                      <br />
                      {post.sidebar.address.cityStateZip}
                    </a>
                  </div>
                  <div className="contact-sidebar-block">
                    <h7>{language === "es" ? "Teléfono" : "Phone"}</h7>
                    <a href={post.sidebar.phone.phonePlusFormat}>
                      {post.sidebar.phone.phoneText}
                    </a>
                  </div>

                  <div className="contact-sidebar-block">
                    <GeneralHours
                      language={language}
                      contact={true}
                      locationHours={locationHours}
                    />
                  </div>
                  <div className="contact-reviews">
                    <AllSocialReviews isColumn />
                    {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                    {/* <BigRating google={google} facebook={facebook} post={post} coastal={coastal} east={east} /> */}
                  </div>
                </>
              )}
            </div>
            <div className="column is-5"></div>
          </div>

          {language === "es" && (
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column">
                <div className="contact-reviews contact-reviews-es big">
                  {/* <SocialReviews inlineStars iconless google facebook googleHeading language={language} /> */}
                  {/* <Ratings language={language} google={google} facebook={facebook} eastGoogle={east} coastalGoogle={coastal} /> */}
                </div>
              </div>
              <div className="column is-4"></div>
            </div>
          )}
        </div>

        <LocationForms activeForm={activeForm} setActiveForm={setActiveForm} />
      </Layout>
    </SharedStateProvider>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    dataJson(optionsItem: { eq: "siteVariables" }) {
      mainLocation
    }
    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        schemaPage
        contactBody {
          heading
          blurb
        }
        sidebar {
          address {
            cityStateZip
            street
          }
          hours
          phone {
            phonePlusFormat
            phoneText
          }
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allLocationsJson(sort: { fields: locationData___order }) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
            }
          }
        }
      }
    }
  }
`

export default ContactUsPage
